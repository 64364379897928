#basic-navbar-nav .icons i {
    font-size: 30px;
    color: white;
    width: 50px;
}

#basic-navbar-nav .icons i:hover{
    color: rgb(121, 121, 121);
}

#basic-navbar-nav #navSections .txt:hover {
    background-color: white;
    color: black;
}
