#jumbotronExp { 
    background-color: #1971c9
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

#wattpadPic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    margin-bottom: 15%;
    width: 50%;
}

#expWord{
    font-size: 9vmin;
    font-family: Poppins, sans-serif; 
    font-weight: 900;
    text-align: center;
    padding-top: 13vh;
}