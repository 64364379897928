#aboutSection {
    height: 100vh;
    background-image: linear-gradient(#212529, #1971c9)
}

/* 'About' word styling */
#jumbotronAbout h1{
    height: 17px;
    font-size: 9vmin;
    padding: 13vh;
    font-family: Poppins, sans-serif; 
    font-weight: 900;
    text-align: center;
}

img {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 10%;
    border-radius: 10px;
}

h3 {
    font-family: Poppins, sans-serif;
    white-space: pre-wrap;
    font-size: 2.7vh;

}

