@import url(https://fonts.googleapis.com/css?family=Roboto:700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#basic-navbar-nav .icons i {
    font-size: 30px;
    color: white;
    width: 50px;
}

#basic-navbar-nav .icons i:hover{
    color: rgb(121, 121, 121);
}

#basic-navbar-nav #navSections .txt:hover {
    background-color: white;
    color: black;
}

@-webkit-keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@-webkit-keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

.intro-name { 
  overflow: hidden;
  white-space: nowrap;
  display: inline;
}

/* "Regan!" part */
#rname {
  font-weight: bold;
}

#jumbotronIntro {
    height: 100vh;
    background-image: linear-gradient(#000000,#212529)
  }
.animated-title {
  font-family: Poppins, sans-serif;
  height: 90vmin;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vmin;
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.animated-title > div div {
  font-size: 12vmin;
  padding: 2vmin 0;
  position: absolute;
}
.animated-title > div div span {
  display: block;
}
.animated-title > div.text-top {
  border-bottom: 1vmin solid #767676;
  top: 0;
}
.animated-title > div.text-top div {
  -webkit-animation: showTopText 1s;
          animation: showTopText 1s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}

.animated-title > div.text-bottom {
  bottom: 0;
}
.animated-title > div.text-bottom div {
  -webkit-animation: showBottomText 0.5s;
          animation: showBottomText 0.5s;
  -webkit-animation-delay: 1.75s;
          animation-delay: 1.75s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
  font-size: 3vw;
  
}

#aboutSection {
    height: 100vh;
    background-image: linear-gradient(#212529, #1971c9)
}

/* 'About' word styling */
#jumbotronAbout h1{
    height: 17px;
    font-size: 9vmin;
    padding: 13vh;
    font-family: Poppins, sans-serif; 
    font-weight: 900;
    text-align: center;
}

img {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 10%;
    border-radius: 10px;
}

h3 {
    font-family: Poppins, sans-serif;
    white-space: pre-wrap;
    font-size: 2.7vh;

}


#jumbotronProjects { 
  background-image: linear-gradient(#1971c9, #212529)
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

#projectWord{
    font-size: 9vmin;
    font-family: Poppins, sans-serif; 
    font-weight: 900;
    text-align: center;
    padding-top: 13vh;
    
}

/* Project Card styling below */

#pokePic, #convoPic{
  max-height: 68vh;
}


.card-header{
  font-size: 3vh;
  text-align: center;
}

.card-subtitle{
  padding-bottom: 10px;
}

.jumbotron-fluid .fab {
    font-size: 17px;
    width: 20px;
}

.card{
    background-color: rgb(127,127,127);
    font-family: Poppins, sans-serif;
    margin-top: 10vmin;
}

.jumbotron-fluid .row .col-sm-4 .btn:hover {
  background-color: gray !important;
  color: black !important;
  
} 
#contactSection {
    height: 30vh;
    max-width: none !important;
    padding-top: 7vh;
    text-align: center;
}

#contactSection.container { 
    background-image: linear-gradient( #212529, #000000)
}

/* "Get in contact with me" text */
h1 {
    text-align: center;
    font-family: Poppins, san-serif;
    font-size: 4vmin;
}

.icons .row i{
    font-size: 40px; 
    color: white;
    padding-top: 50px;
    text-align: center;
}

.icons .row i:hover{
    color: #495057;
}

.black-line{
    background-color: #495057;
}

#jumbotronExp { 
    background-color: #1971c9
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

#wattpadPic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    margin-bottom: 15%;
    width: 50%;
}

#expWord{
    font-size: 9vmin;
    font-family: Poppins, sans-serif; 
    font-weight: 900;
    text-align: center;
    padding-top: 13vh;
}
.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    top: 5vh;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
html {
    scroll-behavior: smooth;
  }
