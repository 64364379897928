#contactSection {
    height: 30vh;
    max-width: none !important;
    padding-top: 7vh;
    text-align: center;
}

#contactSection.container { 
    background-image: linear-gradient( #212529, #000000)
}

/* "Get in contact with me" text */
h1 {
    text-align: center;
    font-family: Poppins, san-serif;
    font-size: 4vmin;
}

.icons .row i{
    font-size: 40px; 
    color: white;
    padding-top: 50px;
    text-align: center;
}

.icons .row i:hover{
    color: #495057;
}

.black-line{
    background-color: #495057;
}
