#jumbotronProjects { 
  background-image: linear-gradient(#1971c9, #212529)
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

#projectWord{
    font-size: 9vmin;
    font-family: Poppins, sans-serif; 
    font-weight: 900;
    text-align: center;
    padding-top: 13vh;
    
}

/* Project Card styling below */

#pokePic, #convoPic{
  max-height: 68vh;
}


.card-header{
  font-size: 3vh;
  text-align: center;
}

.card-subtitle{
  padding-bottom: 10px;
}

.jumbotron-fluid .fab {
    font-size: 17px;
    width: 20px;
}

.card{
    background-color: rgb(127,127,127);
    font-family: Poppins, sans-serif;
    margin-top: 10vmin;
}

.jumbotron-fluid .row .col-sm-4 .btn:hover {
  background-color: gray !important;
  color: black !important;
  
} 